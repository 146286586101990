/* ///////////////////////////////// */
/* //////////// RESET ////////////// */
/* ///////////////////////////////// */
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;600&display=swap');

@font-face {
  font-family: 'Satoshi Variable';
  src: url('./Assets/fonts/Satoshi-Variable.ttf') format('truetype');
  font-display: swap;
  /* Add more font variations here (bold, italic, etc.) if needed */
}

*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  font-family: sans-serif;
  user-select: none;
  cursor: none !important;
  border-style: solid;
}

@media (max-width: 1180px) {
  button,
  a {
    cursor: pointer !important;
  }

  *,
  *::before,
  *::after {
    cursor: auto !important;
  }
}

html {
  overflow-x: hidden;
  overflow-y: overlay;
}

html body {
  min-height: 100vh;
  width: 100vw;
  flex-direction: column;
  padding: 0;
  margin: 0;
  font-family: 'Satoshi Variable', sans-serif;
  background-repeat: no-repeat;
  background-color: #fff;
  scrollbar-width: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
span,
label,
a,
button,
p,
button {
  font-family: 'Satoshi Variable', sans-serif;
}

button:hover {
  opacity: 0.8;
}

body iframe {
  position: fixed;
  z-index: -999;
}

body .HW_frame {
  position: initial;
  z-index: initial;
}

ol,
ul {
  list-style: none;
  margin-bottom: 0rem;
}

h5 {
  margin: 0px !important;
}

textarea,
input {
  webkit-user-select: auto !important;
  user-select: auto !important;
}

@media (max-width: 768px) {
  input,
  textarea {
    font-size: 16px !important;
  }
  input::placeholder,
  textarea::placeholder {
    font-size: 16px !important;
  }
}
